import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo'

// Assets
import bgGrass from '../images/background-header-grass-70.svg'

const ErrorPage = () => {
    const divText = {
        gridArea: "1/1",
        position: "relative",
        placeItems: "end",
        display: "grid"
      };
    const { siteconfigsettings, settingsmenu } = useStaticQuery(
        graphql`
          query {
            siteconfigsettings: file(relativePath: {eq: "settings/general.md"}) {
              id
              childMarkdownRemark {
                frontmatter {
                  settingsitetitle
                  settingsitedefimage {
                    childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          transformOptions: { fit: COVER, cropFocus: CENTER }
                        )
                      }
                  }
                }
              }
            }
            settingsmenu: file(relativePath: {eq: "settings/menu.md"}) {
                childMarkdownRemark {
                  frontmatter {
                    menuonetext
                    menutwotext
                    menuthreetext
                    menuone {
                      link
                      title
                      type
                    }
                    menutwo {
                      link
                      title
                      type
                    }
                    menuthree {
                      link
                      title
                      type
                    }
                  }
                }
              }
          }
        `
      )
      const settings = siteconfigsettings.childMarkdownRemark.frontmatter
      const menu = settingsmenu.childMarkdownRemark.frontmatter
    return (
        <>
        <Layout>
            <Seo />
            <div className="h-80 w-full md:h-425 lg:h-465 xl:h-515" style={{backgroundImage: `url(${bgGrass})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
                <div className="grid h-full relative overflow-hidden">
                    <GatsbyImage image={settings.settingsitedefimage.childImageSharp.gatsbyImageData} alt={settings.settingsitetitle}
                    className="row-start-1 col-start-1" style={{maxHeight: "515px", zIndex: "-10"}}
                    layout="fullWidth"
                    aspectratio={3 / 1}
                    />
                    <div style={divText} className="flex justify-around">
                        <div className="text-center font-cursive max-w-screen-lg">
                            <h1 className=" text-template-accent tracking-wider text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-normal">404 Page Not Found</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto cmscontent mt-36 h-screen">
                <h2>Sorry Page Not Found</h2>
                <ul className="flex flex-col sm:flex-row justify-around tracking-wider pb-4 text-right">
                    <li className="flex-1 py-2">
                        <span className="text-template-accent font-bold md:text-xl lg:text-2xl">{menu.menuonetext}</span>
                        <ul className="font-normal text-sm md:text-base">
                            {menu.menuone.map((item, index) => {
                            return (
                                !item.link.startsWith("http")?
                                <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                :
                                <li key={index}><a href={item.link} target="_blank" rel="noreferrer">{item.title}</a></li>
                            )})}
                        </ul>
                    </li>
                    <li className="flex-1 py-2">
                    <span className="text-template-accent font-bold md:text-xl lg:text-2xl">{menu.menutwotext}</span>
                        <ul className="font-normal text-sm md:text-base">
                            {menu.menutwo.map((item, index) => {
                            return (
                                !item.link.startsWith("http")?
                                <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                :
                                <li key={index}><a href={item.link} target="_blank" rel="noreferrer">{item.title}</a></li>
                            )})}
                        </ul>
                    </li>
                </ul>  
            </div>
        </Layout>
        </>
    )
}

export default ErrorPage